@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700);

#root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
}


body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  -webkit-appearance: none;
}


a:-webkit-any-link {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

input[type="text" i] {
  padding: 5px 5px 0 5px;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }





.hidden {
  display: none;
}

.opacity-0 {
  opacity: 0!important;
  transition: opacity 0.3s;
}

.opacity-1 {
  opacity: 1;
  transition: opacity 1s;
}







h1 {
  margin: 0;
}


h2 {
  margin: 0;
}

h4 {
  margin: 0;
}


.inputBox {
  width: 100%;
  margin: 50px 0 0 0;
}

.tommy-test {
  width: 100%;
  padding-bottom: 100px!important;

}



.tom-flash {
  width: 100%;
  min-height: 200px;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 7px;
}

#skeleton-input {
  width: 100%;
  height: 300px;
}

@keyframes skeleton-loading {
  from {
    background-color: hsl(200, 20% , 75%)
  }
  to {
    background-color: hsl(200, 20%, 95%)
  }
}







.menu-item-active {
  background-color: #e4e9f0a4;
  border-radius: 7px;
  color: #3F7CF6!important;
}

.active-menu-item-border {

  border: 1.5px solid #3F7CF6;
  border-radius: 99px;
  position: absolute;
  left: -12px;
  height: 100%;

}

.active-sub-menu-item-border {

  border: 1.5px solid #3F7CF6;
  border-radius: 99px;
  position: absolute;
  left: -34px;
  height: 100%;

}













.main-wrapper {
  height: 100vh;
  overflow: hidden;
  display: flex;
}




.sidebar-menu {
  position: sticky;
  top: 0;
  min-width: 271px;
  padding: 32px 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  background-color: #F8FAFB;
  border-right: 1px solid #e4e9f0a4;
  box-shadow: 2px 0 3px 0 rgb(0 0 0 / 1%);
  z-index: 1001;

}

.logo-container {
  padding: 0 0 25px 32px;
  border-bottom: 1px solid #e4e9f0a4
}

.menu-items-container {
  padding: 20px 12px 20px 12px;
}

.menu-items-border{
  padding: 25px 0 0 0;
  border-bottom: 1px solid #e4e9f0a4
}



.main-item-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 12px 17px 12px;
  cursor: pointer;
}

.main-item-container:hover {

  background-color: #e4e9f0a4;
  border-radius: 7px;

}


.menu-item-col1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.menu-item-icon {
  display: flex;
}

.menu-item-text {
  font-size: 13px;
  font-weight: 500;
  padding: 0 0 0 12px;
}

.menu-item-number {
  font-size: 12px;
  color:hsl(200, 20% , 45%)
}



.menu-item-new {
  margin: 0 0 0 12px;
  padding: 5px 10px 5px 10px;
  border-radius: 99px;
  background-color: #D7E3D4;
  color: #6FA068;
  font-size: 10px;

}







.training-sub-menu {
  position: relative;
  margin: 0 0 0 20.5px;
  padding: 0 0 0 15px;
  display: flex;
  border-left: 1px dashed #E4E9F0;
}

.sub-menu-col1 {
  min-width: 29px;
  display: flex;
  align-items: center;
}

.sub-menu-circle {
  position: absolute;
  left: 0;
  top: calc(50% - 3px);
  margin: 0 0 0 -18.4px;
}



.sub-menu-container{
  position: relative;
  width: 100%;
  padding: 15px;
  cursor: pointer;
  color: #0E131F;
}

.sub-menu-container:hover {

  background-color: #e4e9f0a4;
  border-radius: 7px;

}

.sub-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}



.sub-menu-item-text {
  font-size: 12px;
}





.other-programs-container {
  padding: 0 12px 20px 12px;

}






/* ACTIVE BORDER STUFF - REAL!! */


.active {
  border-left: 2px solid #3F7CF6;
  margin-left: -12px;
  padding-left: 10px;
  display: block;
  color:#3F7CF6!important;
}

.menu-active-bg {
  background-color: lightgrey;
  border-radius: 7px;
}








.Content {
  position: relative;
  overflow: auto;
  width: 100%;
  padding: 0 0 1200px 0;
  grid-row-start: Content;
  grid-column-start: Content;
  grid-row-end: Content;
  
}

#loader-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 271px;
  bottom: 0;
  z-index: 1001;
  height: 100vh;

}


.loader-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
 

}


.loader {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: calc(40vh);
  width: 100px;
  height: 100px;
  opacity: 1;
 
}

.loader-blocker {

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 1001;
  height: 100vh;
  opacity: 1;

}




.account-wrapper {

  border-top: 1px solid #e4e9f0a4;
  padding: 20px 0 0 0;

}




.side-bottom-area {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;

}


.account-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.act-container {
  display: flex;
  align-items: center;
}

.account-image {
  border-radius: 100%;

}

.account-text {
  font-size: 13px;
  font-weight: 500;
  padding: 0 0 0 12px;
  color: #0E131F;
}


.account-dots {

}




.help-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 99999;
}






.store-selector-container {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
  padding: 12px;
  background-color: white;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid #e4e9f0a4;

}


.s-selector-image {

  width: 25%;


}

.s-selector-div {
  width: 70%;
  padding: 0 15px 0 15px;
  overflow: hidden;

}

.s-selector-store-name {

  font-size: 13px;
  font-weight: 500;
  overflow: hidden;


}

.s-selector-store-url {

  font-size: 12px;
  padding: 5px 0 0 0;
  overflow: hidden;


}

.arrow-wrapper {

  width: 5%;
  

}




.flex-wrapper {
  display: flex;
}






.video-wrapper {
  height: 37vw;
  width: 100%;
}

.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
}


.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1001;
  cursor: pointer;
}

.popup-inner-container {
  width: 100%;
  max-width: 750px;
  max-height: calc(100vh - 6rem);
  min-height: 50vh;
  padding: 30px;
  background-color: white;
  border: 1px solid #e4e9f0a4;
  border-radius: 7px;
  z-index: 1002;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.popup-title {
  margin: 20px 0 0 0;
  font-size: 21px;
  font-weight: 600;
}

.popup-descrp {
  margin: 10px 0 20px 0;
  font-size: 13px;
}


.add-new-name {
  padding: 10px!important;
  min-width: 450px;
  border: 1px solid #dad9da;
  border-radius: 7px;
  font-family: Montserrat;
}

.popup-btn {
  min-width: 470px;
  margin: 10px 0 0 0;
  padding: 15px;
  background-color: black;
  color: white;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 500;
}



.slideout {

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  min-width: 600px;
  padding: 30px;
  background-color: white;
  z-index: 1001;

}



.App {
  display: flex;
}




.tile-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 50px 0 0 0;
  gap: 25px;
}

.tile-wrapper {
  width: 100%;
}

.tile-img {
  width: 100%;
}



.content-wrapper {
  padding: 30px;
}

.tile-bottom-area {
  padding: 15px 15px 20px 2px;
}

.tile-title {
  font-size: 16px;
  font-weight: 500;
}

.tile-descp {
  margin: 5px 0 0 0;
  font-size: 12px;
  
}



.fuck-around {
  width: 400px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  bottom:0;
  background-color:hsl(200, 20% , 45%)
}





#temp-load-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 999;
}


.p10{
  padding:10px;
}


/* CSS FROM THE QUICK MOCKUP OF THE UI FOR THE FULL-PAGE-BUILDER, NEED TO BE TIDIED */

.project-grid {
  display: grid;
  grid-template-rows: 60px calc(100vh - 60px);
  grid-template-columns: 400px 1fr 0.8fr;
  grid-template-areas: 
    "header header header" 
    "search-area middle-area notes-area";
}

.h100 {
  height: 95%;
  margin: 20px 0 0 0;
  border: 1px solid #f2f2f2;
  border-radius: 7px;
}


.top-nav {
  grid-area: header;
  background-color: white;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0 2px 3px rgba(0,0,0,0.025);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.nav-left-area {
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
}

.project-title-area {
  padding: 0 0 0 20px;
  border-left: 1px solid #f2f2f2;
  font-weight: 600;
}


.nav-right-area {
  position: absolute;
  right: 20px;
}

.save-btn {
  padding: 10px 25px 10px 25px;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  background-color: #3F7CF6;
  color: white;
  font-family: Montserrat;
  font-weight: 500;
}

.black-bg {
  background-color: black!important;


}


.template-area {
  margin: 0 -20px 20px -20px;
  padding: 0 20px 30px 20px;
  border-bottom: 1px solid #f2f2f2;
}

.sub-title {
  font-size: 13px;
}


.search-area {
  grid-area: search-area;
  border-right: 1px solid #f2f2f2;
  padding: 30px 20px 30px 20px;
  position: relative;
  overflow: auto;
}

.search-inner {
  padding: 0 0 5000px 0;
  overflow: auto;
}

.bottom-button-area {
  font-weight: 600;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 359px;
  min-height: 60px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
}



.template-selector {
  margin: 15px 0 0 0;
  border: 1px solid #f2f2f2;
  border-radius: 7px;
  padding: 20px;
  cursor: pointer;
}

.template-inner {
  display: flex;
  align-items: center;
}

.template-title {
  margin: 0 0 0 10px;
  font-size: 15px;
}

.font-15 {
  font-size: 15px;
}


.search-input {
  font-family: Montserrat;
  font-size: 15px;
  padding: 15px;
  margin: 15px 0 0 0;
  min-width: calc(100% - 37px);
  max-width: calc(100% - 37px);
  min-height: 250px;
  border: 1px solid #f2f2f2;
  border-radius: 7px;
}




.notes-area {
  grid-area: notes-area;
  background-color: white;
  border-left: 1px solid #f2f2f2;
  padding: 20px;
}


.settings-area {
  margin: 30px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

}



.specifics-area {
  margin: 20px 0 0 0;
}


.persona-area {
  margin: 20px 0 0 0;

  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 30px 0;
}



.response-container {
  margin: 20px 0 20px 0;
  padding: 30px;
  border: 1px solid #f2f2f2;
  border-radius: 7px;
  background-color: white;
  font-size: 17px;
}


.p20 {
  padding: 20px 20px 25px 20px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actf {
  border-bottom: 1px solid #3F7CF6;
}




.middle-area {
  position: relative;
  grid-area: middle-area;
  background-color: #F8FAFB;
  padding: 10px 30px 30px 30px;
  overflow: auto;

}

.middle-tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #efefef;
}






.results-tile-bottom-area {

  border-top: 1px solid #f2f2f2;
  margin: 30px 0 0 0;
  padding: 30px 20px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.results-tile-inner-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.save-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.stage{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}



.return {
  font-size: 13px;
  margin: 0 10px 0 0;

}


.persona-container {
  margin: 15px 0 0 0;
  display: flex;
  align-items: top;
  gap: 10px;


}

.persona-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f2f2f2;
  text-align: center;
  font-size: 12px;
  padding: 10px;
  border-radius: 7px;
  width: 75px;
  min-height: 70px
}







.saved-projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px;
}

.project-tile {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #f2f2f2;
  border-radius: 7px;
  padding: 40px;
  background-color: white;
}

.access-project-btn {
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
  color: white;
  border: none;
  border-radius: 7px;
  margin: 10px 0 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin: 30px 0 0 0;
}




.response-container, .saved-result {
  margin: 20px 0 0 0;
  padding: 30px;
  border: 1px solid #f2f2f2;
  border-radius: 7px;
  background-color: white;
  font-size: 15px;
}



.projects-top-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 20px 0 20px;
}